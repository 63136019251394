import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebase-config';

interface AnalyzeShopifyShopRequest {
  id: string
}

interface AnalyzeShopifyShopResponse {
  id: string;
}

const analyzeShopifyShop = async (id: string): Promise<AnalyzeShopifyShopResponse> => {
  const analyzeShopifyShop = httpsCallable<AnalyzeShopifyShopRequest, AnalyzeShopifyShopResponse>(functions, 'handle_shopify_shop_data');
  const result = await analyzeShopifyShop({ id });
  return result.data;
};

export { analyzeShopifyShop };