// src/store/itemsSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { collection, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../config/firebase-config';

export interface WebsiteImage {
  id: string;
  alt: string;
  llmAlt: string;
  src: string;
  normalizedSrc: string;
  title: string;
  llmTitle: string;
  llmReason: string;
  xpath: string;
  updatedAt: Timestamp;
  originalFormat: string;
  originalDimensions: number[];
  status: string;
}


interface WebsiteImageState {
  images: WebsiteImage[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: WebsiteImageState = {
  images: [],
  status: 'idle',
  error: null,
};


export const fetchWebsiteImages = createAsyncThunk('websites/fetchWebsiteImages', async (websiteId: string) => {
  const querySnapshot = await getDocs(collection(db, 'websites', websiteId, 'images'))
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  })) as WebsiteImage[];
});

const websiteImagesSlice = createSlice({
  name: 'websiteImages',
  initialState,
  reducers: {
    setWebsiteImages: (state, action: PayloadAction<WebsiteImage[]>) => {
        state.images = action.payload;
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsiteImages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWebsiteImages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.images = action.payload;
      })
      .addCase(fetchWebsiteImages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  },
});

export const { setWebsiteImages } = websiteImagesSlice.actions;
export default websiteImagesSlice.reducer;