import { Modal, Button, Input, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ShopifyShop, updateShopifyShop } from '../../store/shopifyShopSlice';
import { ShopifyProduct, updateShopifyProduct } from '../../store/shopifyProductsSlice';
import { ShopifyProductImage, ShopifyProductImageStatus, updateShopifyProductImage } from '../../store/shopifyProductImagesSlice';

const { TextArea } = Input;


interface EditShopifyProductImageDescriptionModalProps {
    open: boolean;
    handleClose: () => void;
    value: string;
    fieldToUpdate: "Alt";
    shopifyProductImage: ShopifyProductImage | null;
}

const EditShopifyProductDescriptionImageModal: React.FC<EditShopifyProductImageDescriptionModalProps> = ({ shopifyProductImage, open, handleClose, value, fieldToUpdate }) => {

    const selectedShopifyShop = useAppSelector((state) => state.shopifyShops.selectedShopifyShop)
    // State to manage the input value
    const [inputValue, setInputValue] = useState<string>(value || '');
    const [saving, setSaving] = useState<boolean>(false);

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (value) {
            setInputValue(value);
        }
    }, [value]);

    // Function to display a success notification
    const openSuccessNotification = () => {
        notification.success({
            message: 'Success',
            description: `${fieldToUpdate} has been successfully updated!`,
            duration: 3, // duration in seconds
        });
    };

    const handleSave = async () => {
        setSaving(true);
        // Simulate saving process (you can replace this with your actual saving logic)

        // TODO: no clean implementation needs to be improved
        if (shopifyProductImage && selectedShopifyShop) {
            if (fieldToUpdate === "Alt") {
                dispatch(updateShopifyProductImage({ id: shopifyProductImage.id, shopId: selectedShopifyShop.id, llmAlt: inputValue, status: ShopifyProductImageStatus.ANALYZED }));
            } else {
                setSaving(false);
                console.error("Error: Field to update not found", fieldToUpdate)
                return
            }

            setSaving(false);
            openSuccessNotification(); // Show success notification after saving
            handleClose(); // Close modal after saving
        } else {
            setSaving(false);
            console.error("Error: Shopify Image not found")
        }
    };

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
    };

    return (
        <Modal
            open={open}
            title={`Edit Shop ${fieldToUpdate}`}
            width={"70vw"}
            height={"90vh"}
            onCancel={handleClose}
            footer={[
                <Button key="cancel" onClick={handleClose} disabled={saving}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" onClick={handleSave} loading={saving} disabled={saving}>
                    {saving ? "Saving" : "Save"}
                </Button>,
            ]}
        >
            {/* Full-size text input preloaded with the fieldToUpdate from Website */}
            <TextArea
                value={inputValue}
                onChange={handleInputChange}
                placeholder={`Edit ${fieldToUpdate}`}
                autoSize={{ minRows: 10, maxRows: 20 }} // Adjust the height dynamically
                style={{ width: '100%', marginTop: '16px' }}
            />
        </Modal>
    );
};

export default EditShopifyProductDescriptionImageModal;
