
import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebase-config';

interface AuthenticateShopifyPrivateAppRequest {
    shopUrl: string;
    apiKey: string;
    accessToken: string;
}

const authenticateShopifyPrivateApp = async (shopUrl: string, apiKey: string, accessToken: string): Promise<void> => {
    const authenticateShopifyPrivateApp = httpsCallable<AuthenticateShopifyPrivateAppRequest, any>(functions, 'shopify_auth_private_app');
    await authenticateShopifyPrivateApp({ shopUrl, apiKey, accessToken });
};

export { authenticateShopifyPrivateApp };