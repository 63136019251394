import React, { useState } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { auth } from '../config/firebase-config';
//import { useNavigate } from 'react-router-dom';
import {
  LoginOutlined,
  ApiOutlined
} from '@ant-design/icons';
import { clearUser, setUser } from '../store/usersSlice';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { useAppDispatch } from '../store/hooks';
import { useNavigate } from 'react-router-dom';
import FullPageLoader from '../components/FullPageLoader';
import { AppRoutes } from '../routes';
import AuthLayout from '../layouts/AuthLayout';

interface SignInValues {
  email: string;
  password: string;
}

const SignInPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);


  // TODO: Check how often executed
  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(setUser(user))
      navigate(AppRoutes.home, { replace: true })
    } else {
      dispatch(clearUser())
    }
    if (loading) {
      setLoading(false)
    }
  })


  const handleLogin = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
    } catch (error) {
      console.error(error)
    }
  }

  const onFinish = (values: SignInValues) => {
    handleLogin(values.email, values.password)
  };

  return (
    <>≈
      {loading && <FullPageLoader />}

      <AuthLayout>
        <Card title={<p><LoginOutlined /> Sign In</p>} style={{ width: 300 }}>
          <Form
            name="normal_login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your E-Mail!' }]}
            >
              <Input placeholder="E-Mail" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' }]}
            >
              <Input type="password" placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }} icon={<LoginOutlined />}>
                Sign In
              </Button>
              <Button type="default" href="/signup" style={{ width: '100%', marginTop: 8 }} icon={<ApiOutlined />} >
                Sign Up
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </AuthLayout>
    </>
  );
};

export default SignInPage;