import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { collection, getDocs, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase-config';


export enum ShopifyProductStatus {
  CREATED = "created",
  ANALYZING = "analyzing",
  ANALYZED = "analyzed",
  FAILED_TO_ANALYZE = "failed to analyze",
  APPROVED = "approved"
}

export interface ShopifyProduct {
  id: string;
  shopifyProductId: number;
  bodyHtml: string;
  handle: string;
  image: string;
  imageCount: number;
  price: string; // should be number
  productType: string;
  shopifyStatus: string;
  shopifyTags: string; // TODO: make this an array
  title: string;
  variantCount: number;
  vendor: string;
  shopifyCreatedAt: Timestamp;
  shopifyUpdatedAt: Timestamp;
  shopifyPublishedAt: Timestamp;
  // generated content
  llmReason?: string;
  llmBodyHtml?: string;
  llmTitle?: string;
  // meta
  status: ShopifyProductStatus;
  updatedAt: Timestamp;
  createdAt: Timestamp;
}


interface ShopifyProductState {
  shopifyProducts: ShopifyProduct[];
  selectedShopifyProduct: ShopifyProduct | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ShopifyProductState = {
  shopifyProducts: [],
  selectedShopifyProduct: null,
  status: 'idle',
  error: null,
};


export const updateShopifyProduct = createAsyncThunk(
  'shopifyProducts/updateShopifyProduct',
  async (updatedShopifyShop: Partial<ShopifyProduct> & { id: string, shopId: string }, { rejectWithValue }) => {
    try {
      const { id, shopId, ...updates } = updatedShopifyShop;
      const timestamp = Timestamp.now();

      // Update the document in Firestore
      await updateDoc(doc(db, 'shopifyShops', shopId, 'shopifyProducts', id), {
        ...updates,
        updatedAt: timestamp,  // Automatically update the updatedAt timestamp
      });

      return { id, ...updates, updatedAt: timestamp };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

export const fetchShopifyProducts = createAsyncThunk('shopifyProducts/fetchShopifyShopProducts', async (shopifyShopId: string) => {
  const querySnapshot = await getDocs(collection(db, 'shopifyShops', shopifyShopId, 'products'));
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  })) as ShopifyProduct[];
});

const shopifyProductsSlice = createSlice({
  name: 'shopifyProducts',
  initialState,
  reducers: {
    setShopifyProducts: (state, action: PayloadAction<ShopifyProduct[]>) => {
      state.shopifyProducts = action.payload;
    },
    setSelectedShopifyProduct: (state, action: PayloadAction<ShopifyProduct | null>) => {
      state.selectedShopifyProduct = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShopifyProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchShopifyProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.shopifyProducts = action.payload;
      })
      .addCase(fetchShopifyProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
  },
});

export const { setShopifyProducts, setSelectedShopifyProduct } = shopifyProductsSlice.actions;
export default shopifyProductsSlice.reducer;