import React from 'react';
import { Form, Input, Button, Card } from 'antd';
import { auth } from '../config/firebase-config';
import { createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import {
  ApiOutlined
} from '@ant-design/icons';
import { clearUser, setUser } from '../store/usersSlice';
import { useAppDispatch } from '../store/hooks';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';

const SignUpPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(setUser(user))
      navigate('/', { replace: true })
    } else {
      dispatch(clearUser())
    }
  })

  const handleSignup = async (email: string, password: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      dispatch(setUser(userCredential.user))
    } catch (error) {
      console.error(error)
    }
  }

  const onFinish = async (values: any) => {
    try {
      await handleSignup(values.email, values.password)
    } catch (error: any) {
      console.log('sign up error:', error)
    }

  };

  const validatePassword = (_: any, value: any) => {
    if (!value || value.length < 8 || !/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return Promise.reject(new Error('Password must be at least 8 characters long and contain a special symbol'));
    }
    return Promise.resolve();
  };

  const compareToFirstPassword = (_: any, value: any) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(new Error('The two passwords do not match'));
    }
    return Promise.resolve();
  };

  return (
    <AuthLayout>
      <Card title={<p><ApiOutlined /> Sign Up</p>} style={{ width: 300 }}>
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="email"
            rules={[
              { type: 'email', message: 'The input is not valid E-mail!' },
              { required: true, message: 'Please input your E-mail!' }
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              { validator: validatePassword }
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              { required: true, message: 'Please confirm your password!' },
              { validator: compareToFirstPassword }
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }} icon={<ApiOutlined />}>
              Sign Up
            </Button>
            <Button type="link" href="/signin" style={{ marginTop: 8 }}>Already have an account? Sign in!</Button>
          </Form.Item>
        </Form>
      </Card>
    </AuthLayout>
  );
};

export default SignUpPage;
