import { BrowserRouter, Route, Routes } from "react-router-dom";
import RootLayout from "../layouts/RootLayout";
import PrivateRoutes from "./PrivateRoutes";
import SignInPage from "../pages/SignInPage";
import SignUpPage from "../pages/SignUpPage";
import ShopifyShopPage from "../pages/ShopifyShopPage";
import ShopifyAnalyzeShopPage from "../pages/ShopifyAnalyzeShopPage";
import { AppRoutes } from "../routes";
import ImprintPage from "../pages/ImprintPage";

export const AppRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route key="protected-routes" element={<PrivateRoutes />}>
                    <Route key="home-page-layout" element={<RootLayout />}>
                        <Route path={AppRoutes.home} element={<ShopifyAnalyzeShopPage />} />
                        <Route path={AppRoutes.shopifyShops} element={<ShopifyShopPage />} />
                        <Route path={AppRoutes.shopifyAnalyzeShop} element={<ShopifyAnalyzeShopPage />} />
                    </Route>
                </Route>
                <Route index path={AppRoutes.signIn} element={<SignInPage />} />
                <Route path={AppRoutes.signUp} element={<SignUpPage />} />
                <Route path={AppRoutes.imprint} element={<ImprintPage />} />
            </Routes>
        </BrowserRouter>)
}