
import React, { useMemo, useState } from 'react';
import { Button, Descriptions, Input, Space } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { ShopifyProduct } from '../../store/shopifyProductsSlice';
import DOMPurify from 'dompurify';
import EditShopifyProductDescriptionModal from './EditShopifyPorductDescriptionModal';

const { Search } = Input
interface ShopifyProductDetailDescriptionProps {
    shopifyProduct: ShopifyProduct | null;
}

const ShopifyProductDetailDescription: React.FC<ShopifyProductDetailDescriptionProps> = ({ shopifyProduct }) => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [editKey, setEditKey] = useState<"Description" | "Title">('Description')
    const [editValue, setEditValue] = useState<string>('')

    const handleEditClick = (editKey: "Description" | "Title", editValue: string | undefined) => {
        if (!editValue) return
        setEditKey(editKey);
        setEditValue(editValue);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setEditValue("");
        setIsModalOpen(false);
    }


    const descriptionItems = useMemo(() => {
        if (shopifyProduct) {
            return [
                {
                    key: 1,
                    label: 'Title',
                    children: shopifyProduct.title,
                    span: 12
                },
                {
                    key: 2,
                    label: <Space direction="horizontal" size="small" style={{ display: 'flex' }}>
                        Rec. Title
                        <Button type="text" onClick={() => handleEditClick('Title', shopifyProduct.llmTitle)} style={{ marginLeft: 8 }} icon={<EditOutlined />} />
                    </Space>,
                    children: shopifyProduct.llmTitle,
                    span: 12
                },
                {
                    key: 3,
                    label: "Description",
                    children: (
                        <div
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(shopifyProduct.bodyHtml) }}
                        />
                    ),
                    span: 12
                },
                {
                    key: 4,
                    label: (
                        <Space direction="horizontal" size="small" style={{ display: 'flex' }}>
                            Rec. Description
                            <Button type="text" onClick={() => handleEditClick('Description', shopifyProduct.llmBodyHtml)} style={{ marginLeft: 8 }} icon={<EditOutlined />} />
                        </Space>
                    ),
                    children: (
                        <div
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(shopifyProduct.llmBodyHtml ?? "") }}
                        />
                    ),
                    span: 12
                },
                {
                    key: 5,
                    label: "Reasoning",
                    span: 24,
                    children: (
                        <div
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(shopifyProduct.llmReason ?? "") }}
                        />
                    ),
                }
            ]
        }
        return []
    }, [shopifyProduct]);

    return (
        <div>
            <Descriptions
                bordered
                size="small"
                column={24}
                items={descriptionItems}
                labelStyle={{ width: "50%", fontSize: 14 }}
                contentStyle={{ width: "50%", fontSize: 14 }}
                layout="vertical"
            />
            <EditShopifyProductDescriptionModal
                open={isModalOpen}
                handleClose={handleModalClose}
                value={editValue}
                fieldToUpdate={editKey}
                shopifyProduct={shopifyProduct}
            />
        </div>
    )
};


export default ShopifyProductDetailDescription;
