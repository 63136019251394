import { Modal, Typography, Button, Input, notification, List } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateWebsite, Website } from '../../store/websitesSlice';
import { ShopifyShop } from '../../store/shopifyShopSlice';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import { setShopifyProducts, ShopifyProduct } from '../../store/shopifyProductsSlice';
import ShopifyShopDescription from './ShopifyShopDescription';


const { TextArea } = Input;

interface ShopifyShopModalProps {
    open: boolean;
    handleClose: () => void;
    shopifyShop: ShopifyShop | null;
}

const ShopifyShopModal: React.FC<ShopifyShopModalProps> = ({ open, handleClose, shopifyShop }) => {

    return (
        <Modal
            open={open}
            title={`Shopify Shop: ${shopifyShop?.shop}`}
            width={"90vw"}
            height={"90vh"}
            onCancel={handleClose}
            footer={[
                <Button key="close" type="primary" onClick={handleClose}>
                    Close
                </Button>,
            ]}
        >
            <ShopifyShopDescription />
        </Modal>
    );
};

export default ShopifyShopModal;
