
import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebase-config';

interface AuthenticateShopifyRequest {
    shopUrl: string;
}

interface AuthenticateShopifyResponse {
    authUrl: string;
}


const authenticateShopify = async (shopUrl: string): Promise<AuthenticateShopifyResponse> => {
    const authenticateShopify = httpsCallable<AuthenticateShopifyRequest, any>(functions, 'shopify_auth');
    const result = await authenticateShopify({ shopUrl });

    console.log('hide website result', result)
    return result.data;
};

export { authenticateShopify };