export const getFlagEmoji = (language: string): string => {
    const flags: { [key: string]: string } = {
        "German": "🇩🇪",    // Germany flag
        "English": "🇬🇧",   // United Kingdom flag
        "Spanish": "🇪🇸",   // Spain flag
        "French": "🇫🇷",    // France flag
        "Italian": "🇮🇹",   // Italy flag
        "Portuguese": "🇵🇹", // Portugal flag
        "Japanese": "🇯🇵",  // Japan flag
        "Chinese": "🇨🇳",   // China flag
        "Russian": "🇷🇺",   // Russia flag
        // Add more languages and their corresponding flags as needed
    };

    // Return the corresponding flag or a default flag if the language is not found
    return flags[language] || language;  // Default to a white flag if the language is not found
}