import React from 'react';
import { Layout, Space } from 'antd';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../routes';

const { Content, Footer } = Layout;

interface AuthLayoutProps {
    children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '24px',
                    backgroundColor: '#f0f2f5',
                }}
            >
                {children}
            </Content>
            <Footer style={{ textAlign: 'center', background: '#f0f2f5' }}>
                <Space>
                    <Link to={AppRoutes.imprint} style={{ color: 'inherit', textDecoration: 'none' }}>
                        Imprint
                    </Link>
                </Space>
            </Footer>
        </Layout>
    );
};

export default AuthLayout;
