import { createSlice } from '@reduxjs/toolkit'
import { User } from 'firebase/auth'
import { RootState } from './store'

interface UsersState {
  currentUser: User | null
}

const initialState: UsersState = {
  currentUser: null
}

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.currentUser = action.payload
    },
    clearUser: (state) => {
      state.currentUser = null
    },
  },
})

export const { setUser, clearUser } = userSlice.actions
export const selectUsers = (state: RootState)=> state.users;
export default userSlice.reducer