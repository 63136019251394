import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebase-config';

interface AnalyzeShopifyProductRequest {
    id: string,
    shopId: string
}

interface AnalyzeShopifyProductResponse {
    id: string;
}

const analyzeShopifyProductImage = async (shopId: string, id: string): Promise<AnalyzeShopifyProductResponse> => {
    try {
        const analyzeShopifyProductImage = httpsCallable<AnalyzeShopifyProductRequest, AnalyzeShopifyProductResponse>(functions, 'handle_analyze_shopify_product_image');
        const result = await analyzeShopifyProductImage({ shopId, id });
        return result.data;
    } catch (error) {
        console.error("Error analyzing shopify product image: ", error);
        throw error;
    }
};

export { analyzeShopifyProductImage };