import { Table, Image, Skeleton, Button, Row, Space, Tooltip, Dropdown, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useMemo, useState } from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { db } from '../../config/firebase-config';
import { setSelectedShopifyProductImage, setShopifyProductImages, ShopifyProductImage, ShopifyProductImageStatus } from '../../store/shopifyProductImagesSlice';
import { BarChartOutlined, DownloadOutlined } from '@ant-design/icons';
import ShopifyProductImageDetailModal from './ShopifyProductImageDetailModal';
import ShopifyProductSelect from '../ShopifyProduct/ShopifyProductSelect';
import { statusColorsProductImage } from '../../constants/statusColors';


interface ShopifyProductImageTableProps {

}


const ShopifyProductImageTable: React.FC<ShopifyProductImageTableProps> = () => {
    const dispatch = useAppDispatch()

    const selectedShopifyShop = useAppSelector((state) => state.shopifyShops.selectedShopifyShop)
    const shopifyProductImages = useAppSelector((state) => state.shopifyProductImages.shopifyProductImages)
    const selectedShopifyProduct = useAppSelector((state) => state.shopifyProducts.selectedShopifyProduct)
    const selectedShopifyProductImage = useAppSelector((state) => state.shopifyProductImages.selectedShopifyProductImage)
    const [shopifyProductImageModalOpen, setShopifyProductImageModalOpen] = useState<boolean>(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [previousSelectedShopifyShopId, setPreviousSelectedShopifyShopId] = useState<string | null>(null)

    useEffect(() => {
        console.log("selectedRowKeys", selectedRowKeys)
    }, [selectedRowKeys])

    useEffect(() => {
        if (selectedShopifyShop && (selectedShopifyShop?.id !== previousSelectedShopifyShopId)) {
            setPreviousSelectedShopifyShopId(selectedShopifyShop.id)

            console.log("ShopifyImageTable: Fetching Shopify Images")
            const q = query(
                collection(db, "shopifyShops", selectedShopifyShop.id, "shopifyProductImages")
            )

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const updatedProductImages = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                } as ShopifyProductImage))

                console.log('updatedProductImages', updatedProductImages)

                dispatch(setShopifyProductImages(updatedProductImages));
            },
                (error) => {
                    console.error("Error fetching real-time updates: ", error);
                }
            );

            return () => unsubscribe();
        }
    }, [selectedShopifyShop])


    const localShopifyProductImages = useMemo(() => {
        if (selectedShopifyProduct) {
            return shopifyProductImages.filter((sp) => sp.internalShopifyProductId === selectedShopifyProduct.id)
        }
        return shopifyProductImages
    }, [shopifyProductImages, selectedShopifyProduct])



    // Add product tile to every shopify porduct image




    // Track updates to shopifyProducts and update selectedShopify product if necessary
    useEffect(() => {
        if (shopifyProductImages && selectedShopifyProductImage) {
            const updatedSelectedShopifyPorductImage = shopifyProductImages.find((sp) => sp.id === selectedShopifyProductImage.id);
            if (updatedSelectedShopifyPorductImage) {
                dispatch(setSelectedShopifyProductImage(updatedSelectedShopifyPorductImage));
            }
        }
    }, [shopifyProductImages])

    const handleShopifyProductImageModalOpen = (shopifyProductImage: ShopifyProductImage) => {
        dispatch(setSelectedShopifyProductImage(shopifyProductImage))
        setShopifyProductImageModalOpen(true)
    }

    const handleShopifyProductImageViewClose = () => {
        setShopifyProductImageModalOpen(false)
        dispatch(setSelectedShopifyProductImage(null))
    }

    const statusFilters = Object.values(ShopifyProductImageStatus).map((status) => ({
        text: status,
        value: status,
    }));

    const columns: ColumnsType<ShopifyProductImage> = [

        {
            title: 'Image',
            dataIndex: 'src',
            key: 'src',
            width: 60,
            render: (src) => (
                <div style={{ width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
                    {src ? (
                        <Image
                            width={40}
                            height={40}
                            src={src}
                            fallback="https://via.placeholder.com/40" // Placeholder image if loading fails
                            style={{ objectFit: 'cover' }}
                        />
                    ) : (
                        <Skeleton.Avatar active size="small" shape="square" />
                    )}
                </div>
            ),
            fixed: 'left'
        },
        {
            title: 'Id',
            dataIndex: 'shopifyImageId',
            key: 'shopifyImageId',
            width: 100,
            render: (shopifyImageId, shopifyProductImage) => <a onClick={() => handleShopifyProductImageModalOpen(shopifyProductImage)}>{shopifyImageId}</a>,
            fixed: 'left',
            sorter: (a, b) => a.status.localeCompare(b.status)
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            width: 100,
            sorter: (a, b) => a.height - b.height, // Numeric sorting
            filters: [
                { text: 'Small (< 100px)', value: 'small' },
                { text: 'Medium (100px - 500px)', value: 'medium' },
                { text: 'Large (> 500px)', value: 'large' },
            ],
            onFilter: (value, record) => {
                if (value === 'small') return record.height < 100;
                if (value === 'medium') return record.height >= 100 && record.height <= 500;
                if (value === 'large') return record.height > 500;
                return false;
            },
        },
        {
            title: 'Width',
            dataIndex: 'width',
            key: 'width',
            width: 100,
            sorter: (a, b) => a.width - b.width, // Numeric sorting
            filters: [
                { text: 'Small (< 100px)', value: 'small' },
                { text: 'Medium (100px - 500px)', value: 'medium' },
                { text: 'Large (> 500px)', value: 'large' },
            ],
            onFilter: (value, record) => {
                if (value === 'small') return record.width < 100;
                if (value === 'medium') return record.width >= 100 && record.width <= 500;
                if (value === 'large') return record.width > 500;
                return false;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status), // Alphabetical sorting
            filters: statusFilters,
            onFilter: (value, record) => record.status === value,
            render: (status: ShopifyProductImageStatus) => (
                <Tag color={statusColorsProductImage[status]}>
                    {status.charAt(0).toUpperCase() + status.slice(1)} {/* Capitalize */}
                </Tag>
            ),
        },
    ];

    const handleMenuClick = (action: string, type: React.Key) => {
        if (action === 'download') {
            if (type === 'all') {
                console.log('Download All');
                // Implement download all logic
            } else if (type === 'selected') {
                console.log('Download Selected');
                // Implement download selected logic
            }
        } else if (action === 'analyze') {
            if (type === 'all') {
                console.log('Analyze All');
                // Implement analyze all logic
            } else if (type === 'selected') {
                console.log('Analyze Selected');
                // Implement analyze selected logic
            }
        }
    };

    const downloadMenu: {
        items: Array<{ key: React.Key; label: string; disabled?: boolean }>;
        onClick: ({ key }: { key: React.Key }) => void;
    } = {
        items: [
            { key: 'all', label: 'Download All' },
            { key: 'selected', label: `Download Selected (${selectedRowKeys.length})`, disabled: selectedRowKeys.length === 0 },
        ],
        onClick: ({ key }) => handleMenuClick('download', key),
    };

    const analyzeMenu: {
        items: Array<{ key: React.Key; label: string; disabled?: boolean }>;
        onClick: ({ key }: { key: React.Key }) => void;
    } = {
        items: [
            { key: 'all', label: 'Analyze All' },
            { key: 'selected', label: `Analyze Selected (${selectedRowKeys.length})`, disabled: selectedRowKeys.length === 0 },
        ],
        onClick: ({ key }) => handleMenuClick('analyze', key),
    };

    return (
        <div>
            <Table
                style={{ marginTop: '8px' }}
                columns={columns}
                dataSource={localShopifyProductImages}
                rowKey="id"
                size='small'
                bordered
                pagination={{
                    showTotal: (total) => `Total ${total} items`
                }}
                title={() => (
                    <Row justify="space-between" align="middle">
                        <Space>
                            <ShopifyProductSelect />
                        </Space>
                        <Space size="small">
                            <Tooltip title="Download">
                                <Dropdown menu={downloadMenu} trigger={['click']}>
                                    <Button
                                        type="default"
                                        icon={<DownloadOutlined />}
                                    />
                                </Dropdown>
                            </Tooltip>
                            <Tooltip title="Analyze">
                                <Dropdown menu={analyzeMenu} trigger={['click']}>
                                    <Button
                                        type="primary"
                                        icon={<BarChartOutlined />}
                                    />
                                </Dropdown>
                            </Tooltip>
                        </Space>
                    </Row>
                )}
                scroll={{ x: 'max-content', y: 570 }}
                locale={{
                    emptyText: selectedShopifyShop
                        ? 'No data available for the selected Shopify shop.'
                        : 'Please select a Shopify shop to display data.',
                }}
                rowSelection={{
                    selectedRowKeys,
                    onChange: (selectedKeys: React.Key[]) => {
                        setSelectedRowKeys(selectedKeys);
                    },
                }}
            />
            <ShopifyProductImageDetailModal
                open={shopifyProductImageModalOpen}
                handleClose={handleShopifyProductImageViewClose}
            />
        </div>
    )
}

export default ShopifyProductImageTable 
