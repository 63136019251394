import React, { useEffect, useState } from 'react';
import { Space, Tabs, TabsProps } from 'antd';
import { useAppDispatch, useAuth } from '../store/hooks';
import { addShopifyShop, setShopifyShops, ShopifyShop } from '../store/shopifyShopSlice';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../config/firebase-config';
import Form from 'antd/es/form';
import ShopifyProductTable from '../components/ShopifyProduct/ShopifyProductTable';
import ShopifyShopDescription from '../components/ShopifyShop/ShopifyShopDescription';
import ShopifyProductImageTable from '../components/ShopifyProductImage/ShopifyProductImageTable';
import { PictureOutlined, ProductOutlined } from '@ant-design/icons';



const ShopifyAnalyzeShopPage: React.FC = () => {
    const auth = useAuth()
    const dispatch = useAppDispatch()

    const [form] = Form.useForm<{ url: string; }>()

    const [activeTabKey, setActiveTabKey] = useState<string>("1")

    useEffect(() => {
        // Get the current user's ID
        const userId = auth?.uid;

        if (!userId) {
            console.error("User is not authenticated");
            return;
        }

        console.log("ShopifyProductPage: Getting Shopify Shops")

        const q = query(
            collection(db, "shopifyShops"),
            where("userId", "==", userId),
            where("status", "!=", "hidden")
        )

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const updatedShopifyShops = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            } as ShopifyShop))
            // Update Redux state with the new data
            dispatch(setShopifyShops(updatedShopifyShops));
        },
            (error) => {
                console.error("Error fetching real-time updates: ", error);
            }
        );

        return () => unsubscribe();
    }, [auth, dispatch])


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: <span>
                <ProductOutlined /> Products
            </span>,
            children: <ShopifyProductTable openImages={() => setActiveTabKey("2")} />,
        },
        {
            key: '2',
            label: <span>
                <PictureOutlined /> Images
            </span>,
            children: <ShopifyProductImageTable />
        },

    ];


    return (
        <div style={{ padding: '20px' }}>
            <Space size="small" direction='vertical' style={{ width: "100%" }}>
                <ShopifyShopDescription />
                <Tabs onChange={(activeKey) => setActiveTabKey(activeKey)} activeKey={activeTabKey} items={items} />
            </Space>
        </div>
    );
};

export default ShopifyAnalyzeShopPage;