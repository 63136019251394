import { Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSelectedShopifyProduct } from '../../store/shopifyProductsSlice';


const ShopifyProductSelect: React.FC = () => {
    const dispatch = useAppDispatch()
    const shopifyProducts = useAppSelector((state) => state.shopifyProducts.shopifyProducts)
    const selectedShopifyProduct = useAppSelector((state) => state.shopifyProducts.selectedShopifyProduct)
    const selectedShopifyShop = useAppSelector((state) => state.shopifyShops.selectedShopifyShop)
    const [selectedValue, setSelectedValue] = useState<string | null>(null)


    const options = useMemo(() => {
        return shopifyProducts.map((shopifyProduct) => ({
            value: shopifyProduct.id,
            label: shopifyProduct.title,
        }));
    }, [shopifyProducts])


    // Set current selectedShopifProduct as selected Value
    useEffect(() => {
        if (selectedShopifyProduct) {
            setSelectedValue(selectedShopifyProduct.id);
        } else {
            setSelectedValue(null);
        }
    }, [selectedShopifyProduct]);

    const handleSelect = (value: string) => {
        if (value !== selectedValue) {
            setSelectedValue(value)
            const selectedShopifyProduct = shopifyProducts.find((sp) => sp.id === value)

            if (selectedShopifyProduct) dispatch(setSelectedShopifyProduct(selectedShopifyProduct))
        }
    }

    const handleClear = () => {
        setSelectedValue(null)
        dispatch(setSelectedShopifyProduct(null))
    }

    return (
        <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select a Product"
            optionFilterProp='label'
            allowClear
            onClear={handleClear}
            options={options}
            disabled={!selectedShopifyShop}
            onSelect={handleSelect}
            value={selectedValue}
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
        />
    )
}

export default ShopifyProductSelect;