import { useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import { onAuthStateChanged, User } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../config/firebase-config";

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const useAuth = () => {
    const [currentUser, setCurrentUser] = useState<User | null | undefined>()
    useEffect(() => {
        const unsub = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user)
        })
        return unsub
    }, [])
    return currentUser
}