import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../store/hooks";
import FullPageLoader from "../components/FullPageLoader";
import { AppRoutes } from "../routes";

const PrivateRoutes: React.FC = () => {
	const currentUser = useAuth()

	//return users.currentUser ? <Outlet /> : <Navigate to="/signin"  replace />;
	return typeof currentUser === 'undefined' ? (
		<FullPageLoader />
	) : currentUser ? (
		<Outlet />
	) : (
		<Navigate to={AppRoutes.signIn} replace />
	);
};

export default PrivateRoutes;