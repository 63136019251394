import { Space, Table, Tag, Image, Tooltip, Skeleton, Row, Dropdown, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { setSelectedShopifyProduct, setShopifyProducts, ShopifyProduct, ShopifyProductStatus } from '../../store/shopifyProductsSlice';
import ShopifyProductDetailModal from './ShopifyProductDetailModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { db } from '../../config/firebase-config';
import { BarChartOutlined, DownloadOutlined } from '@ant-design/icons';
import { useTableSearch } from '../../hooks/useTableSearch';
import { statusColorsProduct } from '../../constants/statusColors';


interface ShopifyProductTableProps {
    openImages?: () => void
}


const ShopifyProductTable: React.FC<ShopifyProductTableProps> = ({ openImages }) => {
    const dispatch = useAppDispatch()

    const selectedShopifyShop = useAppSelector((state) => state.shopifyShops.selectedShopifyShop)
    const shopifyProducts = useAppSelector((state) => state.shopifyProducts.shopifyProducts)
    const selectedShopifyProduct = useAppSelector((state) => state.shopifyProducts.selectedShopifyProduct)

    const [shopifyProductDetailModalOpen, setShopifyProductDetailModalOpen] = useState<boolean>(false)
    const [previousSelectedShopifyShopId, setPreviousSelectedShopifyShopId] = useState<string | null>(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const { getColumnSearchProps } = useTableSearch<ShopifyProduct>();

    useEffect(() => {
        if (selectedShopifyShop && (selectedShopifyShop?.id !== previousSelectedShopifyShopId)) {
            setPreviousSelectedShopifyShopId(selectedShopifyShop.id)

            console.log("ShopifyProductTable: Fetching Shopify Products")
            const q = query(
                collection(db, "shopifyShops", selectedShopifyShop.id, "shopifyProducts")
            )

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const updatedProducts = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                } as ShopifyProduct))
                console.log('updatedProducts', updatedProducts)

                dispatch(setShopifyProducts(updatedProducts));
            },
                (error) => {
                    console.error("Error fetching real-time updates: ", error);
                }
            );

            return () => unsubscribe();
        }
    }, [selectedShopifyShop, dispatch])



    // Track updates to shopifyProducts and update selectedShopify product if necessary
    useEffect(() => {
        if (shopifyProducts && selectedShopifyProduct) {
            const updatedSelectedShopifyPorduct = shopifyProducts.find((sp) => sp.id === selectedShopifyProduct.id);
            if (updatedSelectedShopifyPorduct) {
                dispatch(setSelectedShopifyProduct(updatedSelectedShopifyPorduct));
            }
        }
    }, [shopifyProducts, dispatch])

    const handleShopifyProductDetailModalOpen = (shopifyProduct: ShopifyProduct) => {
        dispatch(setSelectedShopifyProduct(shopifyProduct))
        setShopifyProductDetailModalOpen(true)
    }

    const handleShopifyProductDetailViewClose = () => {
        setShopifyProductDetailModalOpen(false)
        dispatch(setSelectedShopifyProduct(null))
    }

    const handleImageCountClicked = (shopifyProduct: ShopifyProduct) => {
        dispatch(setSelectedShopifyProduct(shopifyProduct))
        if (openImages) openImages()
    }

    const statusFilters = Object.values(ShopifyProductStatus).map((status) => ({
        text: status,
        value: status,
    }));

    const columns: ColumnsType<ShopifyProduct> = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 70,
            render: (image) => (
                <div style={{ width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
                    {image ? (
                        <Image
                            width={40}
                            height={40}
                            src={image}
                            fallback="https://via.placeholder.com/40" // Placeholder image if loading fails
                            style={{ objectFit: 'cover' }}
                        />
                    ) : (
                        <Skeleton.Avatar active size="small" shape="square" />
                    )}
                </div>
            ),
            fixed: 'left'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 250,
            fixed: 'left',
            ...getColumnSearchProps(
                'title',
                (highlightedText, shopifyProduct) => (
                    <Tooltip title={shopifyProduct.title}>
                        <a
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent row-level event conflicts
                                handleShopifyProductDetailModalOpen(shopifyProduct);
                            }}
                        >
                            {highlightedText}
                        </a>
                    </Tooltip>
                )
            ),
        },
        {
            title: 'Tags',
            dataIndex: 'shopifyTags',
            key: 'shopifyTags',
            render: (shopifyTags) => (
                <Space size="small" >
                    {shopifyTags.map((t: string, index: string) => (
                        <Tag key={index}>{t}</Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: 'Images',
            dataIndex: 'imageCount',
            key: 'imageCount',
            width: 100,
            sorter: (a, b) => a.imageCount - b.imageCount, // Numeric sorting
            render: (imageCount, shopifyProduct) => <a onClick={() => handleImageCountClicked(shopifyProduct)}>{imageCount}</a>
        },
        {
            title: 'Variants',
            dataIndex: 'variantCount',
            key: 'variantCount',
            width: 100,
            sorter: (a, b) => a.variantCount - b.variantCount, // Numeric sorting
        },
        {
            title: 'Shopify Status',
            dataIndex: 'shopifyStatus',
            key: 'shopifyStatus',
            width: 150,
            sorter: (a, b) => a.shopifyStatus.localeCompare(b.shopifyStatus), // Alphabetical sorting
            // TODO: add filter
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            filters: statusFilters,
            onFilter: (value, record) => record.status === value,
            sorter: (a, b) => a.status.localeCompare(b.status), // Alphabetical sorting
            render: (status: ShopifyProductStatus) => (
                <Tag color={statusColorsProduct[status]}>
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </Tag>
            )
        }
    ];

    const handleMenuClick = (action: string, type: React.Key) => {
        if (action === 'download') {
            if (type === 'all') {
                console.log('Download All');
                // Implement download all logic
            } else if (type === 'selected') {
                console.log('Download Selected');
                // Implement download selected logic
            }
        } else if (action === 'analyze') {
            if (type === 'all') {
                console.log('Analyze All');
                // Implement analyze all logic
            } else if (type === 'selected') {
                console.log('Analyze Selected');
                // Implement analyze selected logic
            }
        }
    };

    const downloadMenu: {
        items: Array<{ key: React.Key; label: string; disabled?: boolean }>;
        onClick: ({ key }: { key: React.Key }) => void;
    } = {
        items: [
            { key: 'all', label: 'Download All' },
            { key: 'selected', label: `Download Selected (${selectedRowKeys.length})`, disabled: selectedRowKeys.length === 0 },
        ],
        onClick: ({ key }) => handleMenuClick('download', key),
    };

    const analyzeMenu: {
        items: Array<{ key: React.Key; label: string; disabled?: boolean }>;
        onClick: ({ key }: { key: React.Key }) => void;
    } = {
        items: [
            { key: 'all', label: 'Analyze All' },
            { key: 'selected', label: `Analyze Selected (${selectedRowKeys.length})`, disabled: selectedRowKeys.length === 0 },
        ],
        onClick: ({ key }) => handleMenuClick('analyze', key),
    };

    return (
        <div>
            <Table
                style={{ marginTop: '8px' }}
                columns={columns}
                dataSource={shopifyProducts}
                rowKey="id"
                size='small'
                bordered
                pagination={{
                    showTotal: (total) => `Total ${total} items`
                }}
                scroll={{ x: 'max-content', y: 570 }}
                title={() => (
                    <Row justify="end">
                        <Space size="small">
                            <Tooltip title="Download">
                                <Dropdown menu={downloadMenu} trigger={['click']} aria-label="Download Options" >
                                    <Button
                                        type="default"
                                        icon={<DownloadOutlined />}
                                    />
                                </Dropdown>
                            </Tooltip>
                            <Tooltip title="Analyze">
                                <Dropdown menu={analyzeMenu} trigger={['click']} aria-label="Analyze Options">
                                    <Button
                                        type="primary"
                                        icon={<BarChartOutlined />}
                                    />
                                </Dropdown>
                            </Tooltip>
                        </Space>
                    </Row>
                )}
                locale={{
                    emptyText: selectedShopifyShop
                        ? 'No data available for the selected Shopify shop.'
                        : 'Please select a Shopify shop to display data.',
                }}
            />
            <ShopifyProductDetailModal
                open={shopifyProductDetailModalOpen}
                handleClose={handleShopifyProductDetailViewClose}
            />
        </div >
    )
}

export default ShopifyProductTable 
