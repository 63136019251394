// src/store/itemsSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { addDoc, collection, getDocs, onSnapshot, query, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase-config';

enum WebsiteStatus {
  CREATED = "created",
  SCRAPED = "scraped",
  LANGUAGE_DETECTED = "language detected",
  DESCRIPTION_CREATED = "description created",
  METADATA_ANALYZED = "metadata analyzed",
  EXTRACTING = "extracting",
  ANALYZING = "analyzing",
  DONE = "done",
  ERROR = "error",
  HIDDEN = "hidden"
}

export interface Website {
  id: string;
  url: string;
  userId: string;
  status: string;
  hTagsCount?: number;
  imageCount?: number;
  linksCount?: number;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  llmLanguage?: string;
  llmDescription?: string;
  llmKeywords?: string[];
  llmTargetAudience?: string;


}

interface WebsiteState {
  websites: Website[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: WebsiteState = {
  websites: [],
  status: 'idle',
  error: null,
};

// Thunk to update an existing website in Firestore
export const updateWebsite = createAsyncThunk(
  'websites/updateWebsite',
  async (updatedWebsite: Partial<Website> & { id: string }, { rejectWithValue }) => {
    try {
      const { id, ...updates } = updatedWebsite;
      const timestamp = Timestamp.now();

      // Update the document in Firestore
      await updateDoc(doc(db, 'websites', id), {
        ...updates,
        updatedAt: timestamp,  // Automatically update the updatedAt timestamp
      });

      return { id, ...updates, updatedAt: timestamp };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

// Thunk to add a new website to the Firestore database
export const addWebsite = createAsyncThunk(
  'websites/addWebsite',
  async (newWebsite: Omit<Website, 'id' | 'createdAt' | 'updatedAt' | 'status'>, { rejectWithValue }) => {
    try {
      // Get the current Firestore Timestamp
      const timestamp = Timestamp.now();

      const websiteWithTimestamps = {
        ...newWebsite,
        status: WebsiteStatus.CREATED,
        createdAt: timestamp,
        updatedAt: timestamp,
      }

      console.log('websiteWithTimestamps', websiteWithTimestamps)
      
      const docRef = await addDoc(collection(db, 'websites'), websiteWithTimestamps);
      return { id: docRef.id, ...websiteWithTimestamps };
    }  catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

export const fetchWebsites = createAsyncThunk('websites/fetchWebsites', async () => {
  const querySnapshot = await getDocs(collection(db, 'websites'));
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  })) as Website[];
});

const websitesSlice = createSlice({
  name: 'websites',
  initialState,
  reducers: {
    setWebsites: (state, action: PayloadAction<Website[]>) => {
      state.websites = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsites.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWebsites.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.websites = action.payload;
      })
      .addCase(fetchWebsites.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      }).addCase(updateWebsite.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.websites.findIndex(website => website.id === action.payload.id);
        if (index !== -1) {
          // Update the website in the state
          state.websites[index] = { ...state.websites[index], ...action.payload };
        }
      })
      .addCase(updateWebsite.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  },
});

export const { setWebsites } = websitesSlice.actions;
export default websitesSlice.reducer;