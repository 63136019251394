import { Modal, Button, notification, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSelectedShopifyProductImage, ShopifyProductImageStatus, updateShopifyProductImage } from '../../store/shopifyProductImagesSlice';
import ShopifyProductImageDetailDescription from './ShopifyProductImageDetailDescription';
import { statusColorsProductImage } from '../../constants/statusColors';
import { updateShopifyProduct } from '../../store/shopifyProductsSlice';
import { analyzeShopifyProductImage } from '../../api/analyzeShopifyProductImage';

interface ShopifyProductImageDetailModalProps {
    open: boolean;
    handleClose: () => void;
}

const ShopifyProductImageDetailModal: React.FC<ShopifyProductImageDetailModalProps> = ({ open, handleClose }) => {

    const dispatch = useAppDispatch()

    const selectedShopifyShop = useAppSelector((state) => state.shopifyShops.selectedShopifyShop)
    const selectedShopifyProductImage = useAppSelector((state) => state.shopifyProductImages.selectedShopifyProductImage)
    const shopifyProductImages = useAppSelector((state) => state.shopifyProductImages.shopifyProductImages)

    const [currentProductIndex, setCurrentProductIndex] = useState<number>(0)
    const [analyzing, setAnalyzing] = useState<boolean>(false)

    useEffect(() => {
        // TODO: Make this one work with the filtered Products as well
        if (open && selectedShopifyProductImage) {
            const index = shopifyProductImages.findIndex(obj => obj.id === selectedShopifyProductImage.id);
            setCurrentProductIndex(index);
        }
    }, [open, shopifyProductImages, selectedShopifyProductImage]);

    const handleNext = () => {
        const newIndex = Math.min(currentProductIndex + 1, shopifyProductImages.length)
        setCurrentProductIndex(newIndex)
        dispatch(setSelectedShopifyProductImage(shopifyProductImages[newIndex]))
    }

    const handleApproveAndNext = (currentProductIndex: number) => {
        if (selectedShopifyProductImage && selectedShopifyShop) {
            dispatch(updateShopifyProductImage({ id: selectedShopifyProductImage.id, shopId: selectedShopifyShop.id, status: ShopifyProductImageStatus.APPROVED }));
        }
        if (currentProductIndex < shopifyProductImages.length - 1) {
            const newIndex = currentProductIndex + 1
            setCurrentProductIndex(newIndex)
            dispatch(setSelectedShopifyProductImage(shopifyProductImages[newIndex]))
        }
    }

    const handleLast = () => {
        const newIndex = Math.max(currentProductIndex - 1, 0)
        setCurrentProductIndex(newIndex)
        dispatch(setSelectedShopifyProductImage(shopifyProductImages[newIndex]))
    }

    const handleAnalyzeShopifyProduct = async () => {
        console.log("Starting analysis for:", selectedShopifyProductImage, selectedShopifyShop);

        // Check if required data is available
        if (!selectedShopifyProductImage || !selectedShopifyShop) {
            console.error("Product or Shopify shop is not selected.");
            return;
        }

        try {
            setAnalyzing(true);
            console.log("Analyzing product image...");

            await analyzeShopifyProductImage(selectedShopifyShop.id, selectedShopifyProductImage.id);

        } catch (error) {
            console.error("Error analyzing product:", error);

            notification.error({
                message: "Product Image Analysis Failed",
                // description: error.message || "An error occurred during analysis.",
            });
        } finally {
            setAnalyzing(false);
        }
    };


    return (
        <Modal
            open={open}
            title={
                <Space size="small">
                    {selectedShopifyProductImage?.status && (
                        <Tag color={statusColorsProductImage[selectedShopifyProductImage.status]}>
                            {selectedShopifyProductImage.status.charAt(0).toUpperCase() +
                                selectedShopifyProductImage.status.slice(1)}
                        </Tag>
                    )}
                    <span>{'Shopify Product Image'}</span>
                </Space>
            }
            width={"95vw"}
            height={"85vh"}
            onCancel={handleClose}
            footer={[
                <Button key="last" type="default" disabled={analyzing} loading={analyzing} onClick={handleAnalyzeShopifyProduct}>
                    {analyzing ? "Analyzing" : "Analyze"}
                </Button>,
                <Button key="last" type="default" disabled={currentProductIndex === 0} onClick={handleLast}>
                    Last
                </Button>,
                <Button key="next" type="default" disabled={currentProductIndex === shopifyProductImages.length - 1} onClick={handleNext}>
                    Next
                </Button>,
                <Button key="next" type="default" style={{ backgroundColor: statusColorsProductImage.approved, color: 'white' }} onClick={() => handleApproveAndNext(currentProductIndex)}>
                    {/* // handleApproveAndNext(currentProductIndex)}> */}
                    {currentProductIndex === shopifyProductImages.length - 1 ? "Approve" : "Approve & Next"}
                </Button>,
                <Button key="close" type="primary" onClick={handleClose}>
                    Close
                </Button>,
            ]}
            styles={{ body: { height: "75vh", overflow: "hidden" } }}
        >
            <div style={{
                maxHeight: "72vh", // Ensure it doesn't exceed modal height
                overflowY: "auto", // Enable vertical scrolling if content exceeds height
                padding: "16px", // Add padding for better readability
            }}>
                <ShopifyProductImageDetailDescription shopifyProductImage={selectedShopifyProductImage} />
            </div>
        </Modal>
    );
};

export default ShopifyProductImageDetailModal;
