import React, { useState } from 'react';
import { Button } from 'antd';
import Form from 'antd/es/form';
import ShopifyShopTable from '../components/ShopifyShop/ShopifyShopTable';
import AddShopifyShopModal from '../components/ShopifyShop/AddShopifyShopModal';

const ShopifyShopPage: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  return (
    <div style={{ padding: '20px' }}>
      {/* <Button onClick={() => testFunction()} >Test</Button> */}
      <Form layout="inline">
        <Form.Item>
          <Button type="primary" onClick={handleModalOpen}>
            Add Shop
          </Button>
        </Form.Item>
      </Form>
      <ShopifyShopTable />
      <AddShopifyShopModal open={modalOpen} handleClose={handleModalClose} />
    </div>
  );
};

export default ShopifyShopPage;