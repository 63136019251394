// Import the functions you need from the SDKs you need
import exp from "constants";
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import { connect } from "http2";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBCXrB-YjWPHFv1WOllluG_5gVuEORIndA",
  authDomain: "seo-phenix-firebase.firebaseapp.com",
  projectId: "seo-phenix-firebase",
  storageBucket: "seo-phenix-firebase.firebasestorage.app",
  messagingSenderId: "55064176464",
  appId: "1:55064176464:web:8d7084c497f78addb62e55",
  measurementId: "G-3RL80JDHQ1"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);


if (process.env.REACT_APP_USE_EMULATORS === "true") {
  console.log("firebase connecting to emulators")
  connectAuthEmulator(auth, "http://localhost:9099")
  connectFirestoreEmulator(db, "localhost", 8080)
  connectFunctionsEmulator(functions, "localhost", 5001)
}
//const analytics = getAnalytics(app);

export {
  auth,
  db,
  functions
}