// TODO: This could be moved to a ThemeProvider    

import { ShopifyProductImageStatus } from "../store/shopifyProductImagesSlice";
import { ShopifyProductStatus } from "../store/shopifyProductsSlice";


export const statusColorsProductImage = {
    [ShopifyProductImageStatus.CREATED]: 'blue',
    [ShopifyProductImageStatus.ANALYZING]: 'orange',
    [ShopifyProductImageStatus.ANALYZED]: 'green',
    [ShopifyProductImageStatus.FAILED_TO_ANALYZE]: 'red',
    [ShopifyProductImageStatus.NO_MATCHING_PRODUCT_FOUND]: 'gray',
    [ShopifyProductImageStatus.APPROVED]: 'purple',
};


export const statusColorsProduct = {
    [ShopifyProductStatus.CREATED]: 'blue',
    [ShopifyProductStatus.ANALYZING]: 'orange',
    [ShopifyProductStatus.ANALYZED]: 'green',
    [ShopifyProductStatus.FAILED_TO_ANALYZE]: 'red',
    [ShopifyProductStatus.APPROVED]: 'purple',
};
