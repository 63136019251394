import React from 'react';
import { Spin } from 'antd';


const FullPageLoader = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f0f2f5',
            }}>
                <Spin size="large" tip="Loading..."/>
        </div>
    );
};

export default FullPageLoader;