import React, { useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  HomeOutlined,
  EyeOutlined,
  ShopOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, Flex, Space } from 'antd';
import { Outlet } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase-config';
import { useAppDispatch } from '../store/hooks';
import { clearUser } from '../store/usersSlice';
import { AppRoutes } from '../routes';
import ShopifyShopSelect from '../components/ShopifyShop/ShopifyShopSelect';
import { Footer } from 'antd/es/layout/layout';

const { Header, Sider, Content } = Layout;

const RootLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleLogout = async () => {
    try {
      await signOut(auth)
      dispatch(clearUser())
    } catch (error) {
      console.error(error)
    }

  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        {/* TODO: add logo here
        */}
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={[
            {
              key: '1',
              icon: <HomeOutlined />,
              label: 'Home',
              onClick: () => navigate(AppRoutes.home)
            },
            {
              key: '2',
              icon: <EyeOutlined />,
              label: 'Shopify',
              children: [
                {
                  key: '2.1',
                  icon: <ShopOutlined />,
                  label: 'Shops',
                  onClick: () => navigate(AppRoutes.shopifyShops)
                },
                {
                  key: '2.2',
                  icon: <SearchOutlined />,
                  label: 'Analyze Shop',
                  onClick: () => navigate(AppRoutes.shopifyAnalyzeShop)
                },
              ]
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Flex justify="space-between" align='center'>

            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
            <Space size="small">
              <ShopifyShopSelect />
              <Button onClick={handleLogout} danger style={{ marginRight: 16 }} icon={<LogoutOutlined />}>Logout</Button>
            </Space>

          </Flex>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            height: "calc(100vh - 64px - 24px)", // Adjust height for scrollable area
            overflowY: "auto", // Enable vertical scrolling
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
        <Footer style={{ textAlign: 'center', background: colorBgContainer }}>
          <Space>
            <Link to={AppRoutes.imprint} style={{ color: 'inherit', textDecoration: 'none' }}>
              Imprint
            </Link>
          </Space>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default RootLayout;