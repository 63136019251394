import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { collection, getDocs, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase-config';


export enum ShopifyProductImageStatus {
  CREATED = "created",
  ANALYZING = "analyzing",
  ANALYZED = "analyzed",
  FAILED_TO_ANALYZE = "failed to analyze",
  NO_MATCHING_PRODUCT_FOUND = "no matching product found",
  APPROVED = "approved",
}

// TODO: Add shopfiy product to shopify image for eventual consistency
export interface ShopifyProductImage {
  // ids
  id: string;
  internalShopifyProductId: string;
  shopifyImageId: string;
  shopifyProductId: number;

  // values
  alt?: string;
  height: number;
  width: number;
  position: number;
  shopifyCreatedAt: Timestamp;
  shopifyUpdatedAt: Timestamp;
  shopifyVariants: string[]; // TODO: Check if this is the correct type
  src: string;

  // generated
  llmAlt?: string;
  llmReason?: string;

  // meta
  status: ShopifyProductImageStatus;
  updatedAt: Timestamp;
  createdAt: Timestamp;
}


interface ShopifyProductImageState {
  shopifyProductImages: ShopifyProductImage[];
  selectedShopifyProductImage: ShopifyProductImage | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ShopifyProductImageState = {
  shopifyProductImages: [],
  selectedShopifyProductImage: null,
  status: 'idle',
  error: null,
};

export const updateShopifyProductImage = createAsyncThunk(
  'shopifyProductImages/updateShopifyProductImage',
  async (updatedShopifyProductImage: Partial<ShopifyProductImage> & { id: string, shopId: string }, { rejectWithValue }) => {
    try {
      const { id, shopId, ...updates } = updatedShopifyProductImage;
      const timestamp = Timestamp.now();

      // Update the document in Firestore
      await updateDoc(doc(db, 'shopifyShops', shopId, 'shopifyProductImages', id), {
        ...updates,
        updatedAt: timestamp,  // Automatically update the updatedAt timestamp
      });

      return { id, ...updates, updatedAt: timestamp };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);



export const fetchShopifyProductImages = createAsyncThunk('shopifyProducts/fetchShopifyShopProducts', async (shopifyShopId: string) => {
  const querySnapshot = await getDocs(collection(db, 'shopifyShops', shopifyShopId, 'shopifyProductImages'));
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  })) as ShopifyProductImage[];
});

const shopifyProductImagesSlice = createSlice({
  name: 'shopifyProductImages',
  initialState,
  reducers: {
    setShopifyProductImages: (state, action: PayloadAction<ShopifyProductImage[]>) => {
      state.shopifyProductImages = action.payload;
    },
    setSelectedShopifyProductImage: (state, action: PayloadAction<ShopifyProductImage | null>) => {
      state.selectedShopifyProductImage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShopifyProductImages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchShopifyProductImages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.shopifyProductImages = action.payload;
      })
      .addCase(fetchShopifyProductImages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
  },
});

export const { setShopifyProductImages, setSelectedShopifyProductImage } = shopifyProductImagesSlice.actions;
export default shopifyProductImagesSlice.reducer;