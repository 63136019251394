import { Modal, Button, Input, notification, Form, TabsProps, Tabs } from 'antd';
import React, { act, useEffect, useState } from 'react';
import { useAppDispatch, useAuth } from '../../store/hooks';
import { addShopifyShop, ShopifyShop, updateShopifyShop } from '../../store/shopifyShopSlice';
import { authenticateShopify } from '../../api/authenticateShopify';
import { authenticateShopifyPrivateApp } from '../../api/authenticateShopifyPrivateApp';

const { TextArea } = Input;

interface EditShopifyShopDescriptionModalProps {
    open: boolean;
    handleClose: () => void;
}



const EditShopifyShopDescriptionModal: React.FC<EditShopifyShopDescriptionModalProps> = ({ open, handleClose }) => {

    const auth = useAuth()
    const dispatch = useAppDispatch()
    const [loginWithShopifyForm] = Form.useForm<{ url: string; }>()
    const [loginWithPrivateAppForm] = Form.useForm<{ url: string, apiKey: string, accessToken: string }>()
    const [activeTabKey, setActiveTabKey] = useState<string>("loginWithShopify")

    const handleAddShopifyStore = () => {
        if (activeTabKey === 'loginWithShopify') {
            // Handle login with Shopify
            console.log('Login with Shopify');
            loginWithShopifyForm.submit()
        } else if (activeTabKey === 'privateApp') {
            // Handle private app
            console.log('Private App');
            loginWithPrivateAppForm.submit()
        }
    };

    const onFinishLoginWithShopifyForm = async (values: { url: string }) => {
        try {
            // Call your backend to authenticate the Shopify store
            const res = await authenticateShopify(`${values.url}.myshopify.com`);

            if (res.authUrl) {
                // Navigate to the authentication URL
                window.open(res.authUrl, "_blank");
            } else {
                console.error("Authentication URL not provided in response.");
            }
            // Optionally reset the form
            loginWithShopifyForm.resetFields();
        } catch (error) {
            console.error("Error during Shopify authentication:", error);
        }
    };

    const onFinishLoginWithPrivateAppForm = async (values: { url: string, apiKey: string, accessToken: string }) => {
        try {
            // Call your backend to authenticate the Shopify store
            const res = await authenticateShopifyPrivateApp(`${values.url}.myshopify.com`, values.apiKey, values.accessToken);
            // Optionally reset the form
            loginWithPrivateAppForm.resetFields();
        } catch (error) {
            console.error("Error during Shopify authentication:", error);
        }
    };

    const items: TabsProps['items'] = [
        {
            key: 'loginWithShopify',
            label: 'Login with Shopify',
            children: <div style={{ padding: '20px' }}>
                <Form layout="vertical" onFinish={onFinishLoginWithShopifyForm} form={loginWithShopifyForm}>
                    <Form.Item
                        label="Shopify Shop"
                        name="url"
                        rules={[
                            { required: true, message: 'Please input a Shopify Shop!' },
                        ]}
                    >
                        <Input addonAfter=".myshopify.com" />
                    </Form.Item>
                </Form>
            </div>,
        },
        {
            key: 'privateApp',
            label: 'Private App',
            children: <div style={{ padding: '20px' }}>
                <Form layout="vertical" onFinish={onFinishLoginWithPrivateAppForm} form={loginWithPrivateAppForm}>
                    <Form.Item
                        label="Shopify Shop"
                        name="url"
                        rules={[
                            { required: true, message: 'Please input a Shopify Shop!' },
                        ]}
                    >
                        <Input addonAfter=".myshopify.com" />
                    </Form.Item>
                    <Form.Item
                        label="API Key"
                        name="apiKey"
                        rules={[
                            { required: true, message: 'Please input an API Key!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Admin Access Token"
                        name="accessToken"
                        rules={[
                            { required: true, message: 'Please input an Admin Access Token!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </div>,
        },
    ];

    return (
        <Modal
            open={open}
            title={"Add Shopify Shop"}
            width={"70vw"}
            height={"90vh"}
            onCancel={handleClose}
            footer={[
                <Button key="cancel" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" onClick={handleAddShopifyStore}>
                    Add
                </Button>,
            ]}
        >
            <Tabs items={items} onChange={(activeKey) => setActiveTabKey(activeKey)} activeKey={activeTabKey} />

        </Modal>
    );
};

export default EditShopifyShopDescriptionModal;
