import React from 'react';
import logo from './logo.svg';
import './App.css';
import SignUpPage from './pages/SignUpPage';
import { selectUsers } from './store/usersSlice';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignInPage from './pages/SignInPage';
import { useAppSelector } from './store/hooks';
import RootLayout from './layouts/RootLayout';
import { User } from 'firebase/auth';
import { AppRouter } from './router/AppRouter';

function App() {
  return (
    <div className="App">
      <AppRouter />

    </div>
  );
}

export default App;
